
import { defineComponent, ref, nextTick } from 'vue'
import { pxToPx } from '@/utils/rem'
export default defineComponent({
  props: {
    url: String,
    title: String,
    scrollTop: Number
  },
  setup() {
    const opacity = ref(1)
    const self$ = ref<HTMLDivElement>()

    const io = new IntersectionObserver(
      (entries) => {
        const scrollTop = entries[0].boundingClientRect.top
        if (scrollTop > 100) {
          opacity.value = 1
        } else if (scrollTop <= pxToPx(100) && scrollTop > pxToPx(-300)) {
          opacity.value = 0.4
        } else if (scrollTop <= pxToPx(-300) && scrollTop > pxToPx(-500)) {
          opacity.value = 0.1
        } else if (scrollTop <= pxToPx(-500) && scrollTop > pxToPx(-600)) {
          opacity.value = 0
        }
      },
      {
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
        rootMargin: '-100px 0px 1000px 0px'
      }
    )
    nextTick(() => {
      self$.value && io.observe(self$.value)
    })
    return {
      self$,
      opacity
    }
  }
})
