
import { defineComponent, reactive, ref } from "vue";
import ScrollItem from "./ScrollItem.vue";
export default defineComponent({
  components: { ScrollItem },
  setup() {
    const imgList = [
      {
        imgUrl:
          "https://ecompic0.hitv.com/business/e78ab1a8f4972a65525f1e72a4efadb4.png",
        title: "把经典国货穿在身上是种什么体验？",
      },
      {
        imgUrl:
          "https://ecompic0.hitv.com/business/15dcc3e5b87f1d1cb8e06f6ebbad359a.png",
        title: "金牌国货：补水修护王中王",
      },
      {
        imgUrl:
          "https://ecompic0.hitv.com/business/e232f3db0225d64b99b828aba442facf.png",
        title: "万物皆可自制冰糖葫芦！相信我怪力少女妮",
      },
      {
        imgUrl:
          "https://ecompic0.hitv.com/business/18abc1aa5299fac505178423f4836a10.png",
        title: "看起来“很好喝”的汉服穿搭—荔枝仙女露",
      },
      {
        imgUrl:
          "https://ecompic0.hitv.com/business/ff4e690dae9d8e67d78d629102c18a10.png",
        title: "幸福感爆棚的平价家居好物安利",
      },
      {
        imgUrl:
          "https://ecompic0.hitv.com/business/0a7c6b8d3894c51a9723814353e6cc5f.png",
        title: "这些国货老牌首饰，简直大型真香现场！",
      },
      {
        imgUrl:
          "https://ecompic0.hitv.com/business/2293b179b08e08802c11f2de3fd7fff0.png",
        title: "看古风仙气造型，听幽婉流水之音",
      },
      {
        imgUrl:
          "https://ecompic0.hitv.com/business/ab79d0938d5ca4321cf272f671b7c2f1.png",
        title: "感受传统文化之美，塑造民族自豪之情",
      },
      {
        imgUrl:
          "https://ecompic0.hitv.com/business/39eeede59f5c64e29143d1910627ed58.png",
        title: "烧鸡原地封神！农科院研发美食🤤国货之光",
      },
      {
        imgUrl:
          "https://ecompic0.hitv.com/business/aaaeeee2c840e989660cc21acae9f9d7.png",
        title: "“成分至上”的国货精华分享~",
      },
      {
        imgUrl:
          "https://ecompic0.hitv.com/business/da7cd8fc2e23a22731ba7242dab19f67.png",
        title: "国货测评：最近入手的单品，不只是便宜~",
      },
      {
        imgUrl:
          "https://ecompic0.hitv.com/business/c5228de5c0ffbe39c6ee62091b064a27.png",
        title: "巧夺天工的川之御菜——牡丹鱼片",
      },
      {
        imgUrl:
          "https://ecompic0.hitv.com/business/b388bedcaace62b1d625946a8b5fb4b9.png",
        title: "国风小众饰品合集来啦~~~",
      },
      {
        imgUrl:
          "https://ecompic0.hitv.com/business/04d21e1578eaedfb4bbdf9f07314a525.png",
        title: "海鲜也可以中国风style",
      },
      {
        imgUrl:
          "https://ecompic0.hitv.com/business/9cbed783bf7043357380a895048105f9.png",
        title: "明星同款国潮，你不想来一件么",
      },
      {
        imgUrl:
          "https://ecompic0.hitv.com/business/32f8d70466ab45c473b7e323a59e6d6c.png",
        title: "帮铲屎官们省钱，这些主人神器你必须拥有",
      },
      {
        imgUrl:
          "https://ecompic0.hitv.com/business/65369ab5623fd2f04bb7c8633a5952bd.png",
        title: "1、 在线教学：汉服少女妆容美cry！",
      },
      {
        imgUrl:
          "https://ecompic0.hitv.com/business/c3bbcb8d41421787816c12bf0fa54059.png",
        title: "你选对了过冬的宋制汉服吗？",
      },
    ];
    let stripeTransform = reactive([
      {
        paddingTop: 380,
        top: 0,
        scale: 1,
      },
      {
        paddingTop: 100,
        top: 0,
        scale: 0.8,
      },
      {
        paddingTop: 260,
        top: 0,
        scale: 0.9,
      },
    ]);

    const scroll$ = ref<HTMLDivElement>();
    // window.addEventListener("scroll", () => {
    //   const top = scroll$.value?.getBoundingClientRect().top || 0;
    // if (top < 0) {
    //   stripeTransform.forEach((v) => {
    //     v.top = top * v.scale;
    //   });
    // } else {
    //   stripeTransform.forEach((v) => {
    //     v.top = 0;
    //   });
    // }
    // });

    return {
      stripeTransform,
      scroll,
      imgList,
      scroll$,
    };
  },
});
