
import { computed, defineComponent, nextTick, reactive, ref } from "vue";
import { pxToRem } from "@/utils/rem";
import ScrollWrap from "./components/ScrollWrap.vue";
export default defineComponent({
  name: "Home",
  components: { ScrollWrap },
  setup() {
    const brandImages = reactive([
      require("../../assets/image/banner1.png"),
      require("../../assets/image/banner2.png"),
      require("../../assets/image/banner3.png"),
    ]);
    const activeBrand = ref(0);
    const toggleActiveBrand = (index: number) => {
      activeBrand.value = index;
    };

    // 用于渲染品牌
    const showRound = ref(false);
    const round$ = ref<HTMLDivElement>();
    const round = computed(() => {
      const roundList = [
        {
          size: 118,
          content: "汉服",
          opacity: 0.9,
          top: 59,
          left: -128,
        },
        {
          size: 112,
          content: "潮玩",
          opacity: 0.9,
          top: 107,
          left: 314,
        },
        {
          size: 103,
          content: "国货榜单",
          opacity: 0.6,
          top: -122,
          left: 164,
        },
        {
          size: 78,
          content: "0 元试用",
          opacity: 0.32,
          top: -11,
          left: 235,
        },
        {
          size: 77,
          content: "设计师款",
          opacity: 0.32,
          top: 200,
          left: 259,
        },
        {
          size: 85,
          content: "国货爆款",
          opacity: 0.6,
          top: 255,
          left: 122,
        },
        {
          size: 68,
          content: "新潮国货",
          opacity: 0.32,
          top: 208,
          left: -125,
        },
        {
          size: 118,
          content: "国风美妆",
          opacity: 0.6,
          top: 282,
          left: 2,
        },
        {
          size: 71,
          content: "国货上新",
          opacity: 0.32,
          top: -70,
          left: -112,
        },
        {
          size: 94,
          content: "国货美食",
          opacity: 0.6,
          top: -57,
          left: -18,
        },
        {
          size: 40,
          opacity: 0.12,
          top: -152,
          left: -29,
        },
        {
          size: 40,
          opacity: 0.12,
          top: 144,
          left: -13,
        },
        {
          size: 33,
          opacity: 0.12,
          top: 328,
          left: 111,
        },
      ];
      if (showRound.value) {
        return roundList.map((v) => {
          return {
            size: pxToRem(v.size),
            opacity: v.opacity,
            top: pxToRem(v.top),
            left: pxToRem(v.left),
            content: v.content,
            fontSize: pxToRem(v.size / 3.69),
            padding: pxToRem(v.size / 4.4),
          };
        });
      } else {
        return roundList.map((v) => {
          return {
            size: pxToRem(v.size),
            opacity: 0,
            top: "50%",
            left: "50%",
            content: v.content,
            fontSize: pxToRem(v.size / 3.69),
          };
        });
      }
    });
    const io = new IntersectionObserver(
      (entries) => {
        console.log(entries[0]);
        if (entries[0].intersectionRatio >= 1) {
          showRound.value = true;
        } else if (entries[0].intersectionRatio < 1) {
          showRound.value = false;
        }
        nextTick(() => {
          console.log(round);
        });
      },
      {
        threshold: [1],
      }
    );
    nextTick(() => {
      round$.value && io.observe(round$.value);
    });

    return {
      brandImages,
      activeBrand,
      toggleActiveBrand,
      round,
      round$,
      pxToRem,
    };
  },
});
